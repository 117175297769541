<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>

        <div class="row">
            <div class="col-lg-7 px-0">
                <div class="card">
                    <div class="card-body">
                        <h4 class="header-title font-weight-bold"> List of suppliers</h4>
                        <div>
                            <lottie-loader :loading="showLoader" />
                        </div>
                        <p class="text-muted font-13 mb-4"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-right"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0" id="table">
                            <b-table
                                    table-class="table table-centered w-100"
                                    thead-tr-class="bg-light"
                                    :items="ListOfSuppliers"
                                    :fields="tableFields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >

                                <template #cell(phone)="data">
                                    <span class="font-14 text-dark">{{ data.item.phone }}</span>
                                </template>
                                <template #cell(dateCreated)="data">
                                    <span class="font-12 font-weight-bold text-dark">{{ data.item.dateCreated | moment("MMMM Do YYYY") }}</span>
                                </template>
                                <template #cell(name)="data">
                                    <span class="font-14 font-weight-bold text-dark">   {{ data.item.name}}</span>
                                </template>
                                <template #cell(actions)="data">
                                    <a class="mr-2 btn btn-danger" style="cursor: pointer"  href="#" @click="DeleteSupplier(data.item)" > <i class="mdi mdi-trash-can"></i>Delete</a>
                                    <a class="mr-2 btn btn-info" style="cursor: pointer"  href="#" @click="EditSupplier(data.item.id)"> <i class="mdi mdi-pencil-minus"></i>Edit</a>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                        class="dataTables_paginate paging_simple_numbers float-right"
                                >
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 ">
                <div class="card">
                    <div class="card-body">
                          <h4 class="header-title font-weight-bold mb-3"> Create / Update Supplier</h4>

                        <form action="#" @submit.prevent="CreateOrUpdateSupplier">

                            <div class="form-group mb-3">
                                <label for="name">Supplier name</label>
                                <input
                                        class="form-control"
                                        v-model="supplierAdd.name"
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Enter supplier name"
                                        v-validate="'required'"
                                        :class="{'is-invalid': errors.has('name') }"
                                        @input="GetUserNameAfterTyping"
                                />
                                <span v-show="errors.has('name')" class="help text-danger">{{ errors.first('name') }}</span>
                            </div>

                            <div class="form-group mb-3">
                                <label for="name">Supplier phone number</label>
                                <input
                                        class="form-control"
                                        v-model="supplierAdd.phone"
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        placeholder="Enter supplier phone number"
                                        v-validate="'required'"
                                        :class="{'is-invalid': errors.has('phone') }"
                                />
                                <span v-show="errors.has('phone')" class="help text-danger">{{ errors.first('phone') }}</span>
                            </div>

                            <div class="form-group mb-3">
                                <label for="name">Supplier link(Url)</label>
                                <input
                                        class="form-control"
                                        v-model="supplierAdd.link"
                                        type="text"
                                        id="link"
                                        placeholder=""
                                />
                            </div>
                            <div class="form-group mb-3">
                                <label for="name">Supplier Logo Url</label>
                                <input
                                        class="form-control"
                                        v-model="supplierAdd.logoUrl"
                                        type="text"
                                        id="logoUrl"
                                        placeholder=""
                                />
                            </div>

                            <div class="form-group mb-3">
                                <label for="name">Supplier Logo thumbnail url</label>
                                <input
                                        class="form-control"
                                        v-model="supplierAdd.logoThumbnailUrl"
                                        type="text"
                                        id="logoThumbnailUrl"
                                        placeholder=""
                                />
                            </div>
                            <div class="form-group mb-0 text-center">
                                <button class="btn btn-primary float-right" :class="supplierAdd.id ? 'btn-warning' : 'btn-primary'" type="submit">
                                    {{AddOrUpdateSupplier}}
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/Page-header";
    import LottieLoader from "../../../../components/LottieLoader";
    import BaseUrl from "../../../../components/constants/index"
    import ToastConfigAlert from "../../../../../ToastConfig";
    import SweetAlertWrapper from "../../../../../sweetAlert";
    import SweetAlertConfig from "../../../../../SwalConfig"

    export default {
        name: "ManageSupplier",
        components : {
          Layout,
          PageHeader,
            LottieLoader
        },
        data() {
            return {
                showLoader : false,
                bearer :'',
                SuppliersListRaw : [],
                AddOrUpdateSupplier : 'Add Supplier',
                supplierAdd : {
                    id :'',
                    name :'',
                    phone:'',
                    link :'',
                    logoUrl:'',
                    logoThumbnailUrl :''
                },
                ListOfSuppliers : [],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortDesc: true,
                sortBy: "id",
                title: "Manage Suppliers",
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "Suppliers",
                        active: true,
                    },
                ],
                tableFields: [
                    {
                        thClass: 'd-none', tdClass: 'd-none',
                        key: "id",
                        sortable: true,
                    },
                    {
                        label:"Name",
                        key: "name",
                        sortable: true,
                    },
                    {
                        label :"Phone number",
                        key: "phone",
                        sortable: true,
                    },
                    {
                        label:"Date createed",
                        key: "dateCreated",
                        sortable: true,
                    },
                    {
                        key: "actions",
                        sortable: true,
                    },
                ],

            }
        },
        methods : {
            GetUserNameAfterTyping() {
                clearTimeout(this.debounceTimeout);
                this.debounceTimeout = setTimeout(() => {
                    let name = this.supplierAdd.name.toLocaleLowerCase().replace(/\s+/g, '');
                    this.supplierAdd.link = `http://${name}`
                }, 500);
            },
            EditSupplier(Id) {
                const selectedData = this.SuppliersListRaw.find((data) => data.id === Id)
               // console.log("Selected data : ", selectedData.name)
                this.clearAddSupplier();
                this.AddOrUpdateSupplier = "Update Supplier"
               this.supplierAdd.id = selectedData.id;
               this.supplierAdd.phone = selectedData.phoneNumber;
               this.supplierAdd.name = selectedData.name;
               this.supplierAdd.logoUrl = selectedData.logo;
               this.supplierAdd.link = selectedData.link;
               this.supplierAdd.logoThumbnailUrl = selectedData.logoThumbnail;
            },
            DeleteSupplier(data) {
                this.sweetAlert.showDialogYesOrNo(Object.assign(SweetAlertConfig, {
                    title : `Delete ${data.name} ?`,
                    confirmButtonText :'Yes Delete'
                }), () => {


                    this.showLoader = true


                    BaseUrl.AxiosBearer.delete("/store/suppliers/"+data.id).then((response) => {
                        this.showLoader = false
                        if(!response.data.status) {
                            this.$bvToast.toast(response.data.message, ToastConfigAlert)
                        }
                        else {
                            this.getListOfSuppliers()
                            this.sweetAlert.showMessage("Supplier deleted!  ",response.data.message)
                        }
                    }).catch((error) => {
                        this.showLoader = false
                        this.$bvToast.toast(error.message, ToastConfigAlert)
                    })
                })
            },
            CreateOrUpdateSupplier() {
                this.$validator.validateAll().then((result) => {
                        if (result) {

                            this.sweetAlert.showDialogYesOrNo(Object.assign(SweetAlertConfig, {
                                title : `${this.supplierAdd.id ? 'Update Supplier ?' : 'Add Supplier ?'}`,
                                confirmButtonText :`Yes ${this.supplierAdd.id  ? 'Update' : 'Add'}`
                            }), () => {

                                    this.showLoader = true
                                  if(this.supplierAdd.id === '' || this.supplierAdd.id === null) {
                                      this.AddSupplier()
                                  }
                                  else {
                                      this.UpdateSupplier()
                                  }
                                this.AddOrUpdateSupplier = "Add Supplier"

                              })

                        }
               })
            },
            AddSupplier() {

                const userData = {
                    name : this.supplierAdd.name,
                    phoneNumber : this.supplierAdd.phone,
                    link : this.supplierAdd.link,
                    logo : this.supplierAdd.logoUrl,
                    logoThumbnail : this.supplierAdd.logoThumbnailUrl
                }
                // console.log(" add supplier : ", userData)
                BaseUrl.AxiosBearer.post("/store/suppliers", userData).then((response) => {
                    this.showLoader = false
                    if(!response.data.status) {
                        this.$bvToast.toast(response.data.message, ToastConfigAlert)
                    }
                    else {
                        this.getListOfSuppliers()
                        this.clearAddSupplier()
                        this.sweetAlert.showMessage("Supplier created!  ",response.data.message)
                    }
                }).catch((error) => {
                    this.showLoader = false
                    this.$bvToast.toast(error?.message, ToastConfigAlert)
                })
            },
            UpdateSupplier() {

                const userData = {
                    id : this.supplierAdd.id,
                    name : this.supplierAdd.name,
                    phoneNumber : this.supplierAdd.phone,
                    link : this.supplierAdd.link,
                    logo : this.supplierAdd.logoUrl,
                    logoThumbnail : this.supplierAdd.logoThumbnailUrl
                }
                BaseUrl.AxiosBearer.put("/store/suppliers", userData).then((response) => {
                    this.showLoader = false
                    if(!response.data.status) {
                        this.$bvToast.toast(response.data.message, ToastConfigAlert)
                    }
                    else {
                        this.getListOfSuppliers()
                        this.clearAddSupplier()
                        this.sweetAlert.showMessage("Supplier updated!  ",response.data.message)
                    }
                }).catch((error) => {
                    this.showLoader = false
                    this.$bvToast.toast(error?.message, ToastConfigAlert)
                })
            },
            clearAddSupplier() {
                this.supplierAdd = {
                    link: '',
                    name: '',
                    logoThumbnailUrl: '',
                    logoUrl: '',
                    phone: ''
                }
                this.$validator.reset();
            },
            getListOfSuppliers() {

                this.$http.get(`${BaseUrl.URL}/store/suppliers`).then((response) => {

                    if(response.data.status) {
                        this.ListOfSuppliers = []
                        this.SuppliersListRaw = []
                        this.SuppliersListRaw = response.data.payload

                         response.data.payload.forEach((data) => {
                             this.ListOfSuppliers.push({
                                 id : data.id,
                                  name : data.name,
                                 phone : data.phoneNumber,
                                 dateCreated : data.createdAt

                             })
                         })
                    }
                    else{
                        this.$bvToast.toast(response.data.message,ToastConfigAlert)
                    }

                }).catch((error) => {
                    console.log(" error : ", error)
                 this.$bvToast.toast(error.message,ToastConfigAlert)
                })

            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering

                //  this.fundtransferHistoryFiltered = filteredItems
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
        },
        created() {
            this.getListOfSuppliers()
            this.sweetAlert = new SweetAlertWrapper()
            },
        computed: {
            rows() {
                return this.ListOfSuppliers.length;
            },
        },
    }
</script>

<style scoped>

</style>